import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';

import {MY_GITHUB, MY_EMAIL} from '../lib/strings';

/**
 * HomeView containing links to GitHub and my email
 */
export default function HomeView() {
  return (
    <div className="HomeViewIconsWrapper">
      <a className="HomeViewIcon" href={MY_GITHUB} target="_blank" rel="noreferrer" >
        <FontAwesomeIcon icon={faGithub} size='4x' style={{marginRight: 20}} />
      </a>
      <a className="HomeViewIcon" href={`mailto:${MY_EMAIL}`} >
        <FontAwesomeIcon icon={faEnvelopeSquare} size='4x' />
      </a>
    </div>
    )
}